export function useTopOfPage() {
  const [isTopOfPage, setIsTopOfPage] = React.useState(true)

  React.useEffect(
    () => {
      handleScroll()

      window.addEventListener('scroll', handleScroll)

      return () => window.addEventListener('scroll', handleScroll)

      function handleScroll() {
        setIsTopOfPage(window.scrollY === 0)
      }
    },
    [setIsTopOfPage]
  )

  return isTopOfPage
}
