import { Tile } from '/features/buildingBlocks/tile/Tile'
import { useMediaQuery } from '@kaliber/use-media-query'
import { useTopOfPage } from '/machinery/useTopOfPage'
import { useTranslate } from '/machinery/I18n'
import { ShowRewardCalculatorPortalFlowButton } from '/features/buildingBlocks/reward-calculator/ShowRewardCalculatorPortalFlowButton'

import mediaStyles from '/cssGlobal/media.css'
import styles from './TileGrid.css'

export function TileGrid({
  tiles,
  isDutchVacancy,
  linkRef = undefined,
  initialScale = undefined,
  layoutClassName = undefined,
  availableStyleContexts = undefined
}) {
  const { __ } = useTranslate()
  const topOfPage = useTopOfPage()
  const viewportMd = useMediaQuery(mediaStyles.viewportMd) ?? false

  const compactGrid = viewportMd ? topOfPage : false

  return (
    <ul className={cx(styles.component, layoutClassName)}>
      {tiles?.filter(x => x.representation?._type)?.map((x, i) => (
        <li key={[i, x._id].join()} className={cx(styles.gridItem, compactGrid && styles.compact)}>
          <Tile
            tile={x}
            blankTile={topOfPage && viewportMd}
            {...{ availableStyleContexts }}
          />
        </li>
      ))}

      {isDutchVacancy && (
        <li className={cx(styles.gridItem, compactGrid && styles.compact)}>
          <ShowRewardCalculatorPortalFlowButton
            buttonType='tile'
            label={__`salary-checker`}
            dataX='link-to-salary-detail'
            layoutClassName={styles.calculatorLayout}
            {...{ linkRef, initialScale }}
          />
        </li>
      )}
    </ul>
  )
}
